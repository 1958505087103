import { handleVisibleLayers } from './layers.js'
function ajax(a, b) {

    return new Promise((resolve) => {

        fetch(a, b).then((response) => {
            if (response.ok) {
                resolve(response.json());
            }
        }).catch(() => {
            resolve(new Promise(function (resolve) {
                resolve([]);
            }));
        });

    });

}

function handleRouteLayers(config, path) {

    const route = config.routes.find((route) => route.path == path);

    if (route && route.layers) {

        let routeLayers = [];
        for (let i = 0; i < config.groups.length; i++) {
            const group = config.groups[i];

            if (route.layers.includes(group.title)) {
                routeLayers = routeLayers.concat(group.layers);
            } else {
                for (let j = 0; j < group.layers.length; j++) {
                    const layer = group.layers[j];
                    if (route.layers.includes(layer.title)) {
                        routeLayers.push(layer);
                    }
                }
            }

        }

        const routeLayersIds = routeLayers.map((layer) => layer.id);
        handleVisibleLayers(config.groups, routeLayersIds, window.app.map);

    }

}

export { ajax, handleRouteLayers };