<template>
  <div>
    <h4>Anomalias</h4>
    <el-table
      :data="anomalies"
      style="width: 100%"
      empty-text="Sem dados"
      :border="true"
    >
      <el-table-column prop="mslink" label="ID" width="50" />
      <el-table-column prop="tipoAnamoliaDescricao" label="Descrição" />
    </el-table>
  </div>
</template>

<script>
import { getPostAnomalyData } from "../../services/agiltec";
export default {
  name: "PostAnomalyComponent",
  props: ["postId"],
  data() {
    return {
      anomalies: [],
    };
  },
  onMounted() {
    this.getData();
  },
  methods: {
    getData() {
      getPostAnomalyData(this.postId).then((response) => {
        this.anomalies = response.source;
      });
    },
  },
  watch: {
    postId() {
      this.getData();
    },
  },
};
</script>

<style>
</style>