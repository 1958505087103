<template>
  <div>
    <Teleport to="#map">
      <div class="times-slider">
        <el-tooltip
          class="box-item"
          content="Selecione uma camada para começar"
          placement="top"
          :visible="!this.layerId"
        >
          <input
            v-model="range"
            type="range"
            min="1"
            max="100"
            class="slider"
            @change="listener"
            @input="listener"
          />
        </el-tooltip>
        <el-row :gutter="20" style="padding: 1rem">
          <el-col :span="4" :offset="10">
            <el-select
              v-model="layerId"
              placeholder="Selecione a camada"
              filterable
              style="display: block"
            >
              <el-option-group
                v-for="group in options"
                :key="group.label"
                :label="group.label"
              >
                <el-option
                  v-for="item in group.options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-option-group>
            </el-select>
          </el-col>
        </el-row>
      </div>
    </Teleport>
  </div>
</template>

<script>
import { getRenderPixel } from "ol/render";

// eslint-disable-next-line

export default {
  name: "SwapComponent",
  // components: { WidgetComponent },
  data() {
    return {
      layerId: null,
      range: 50,
      options: [],
      swapLayer: null,
    };
  },
  mounted() {
    this.fillData();
    this.emitter.on("add-layer", this.fillData);
  },

  unmounted() {
    let layer = this.getLayerById(this.layerId);
    this.unbindEvents(layer);
  },
  watch: {
    layerId: function (newId, oldId) {
      if (oldId) {
        let oldLayer = this.getLayerById(oldId);
        this.unbindEvents(oldLayer);
      }
      let newLayer = this.getLayerById(newId);
      this.bindEvents(newLayer);
    },
  },
  methods: {
    fillData() {
      this.options = this.app.config.groups.map((group) => {
        let validLayer = group.layers.filter((layer) => {
          return layer.type != "heatmap" && layer.visible;
        });
        return {
          label: group.title,
          options: validLayer.map((layer) => {
            return {
              label: layer.title,
              value: layer.id,
            };
          }),
        };
      });
    },
    getLayerById(id) {
      let olLayer;
      this.app.config.groups.forEach((group) => {
        group.layers.forEach((layer) => {
          if (layer.id === id && layer.type != "heatmap") {
            olLayer = layer.ol;
          }
        });
      });
      return olLayer;
    },
    bindEvents(layer) {
      this.app.map.render();
      layer.on("prerender", this.prerender);
      layer.on("postrender", this.postrender);
    },
    unbindEvents(layer) {
      layer?.un("prerender", this.prerender);
      layer?.un("postrender", this.postrender);
      this.app.map.render();
    },
    prerender(event) {
      let ctx = event.context;
      const mapSize = this.app.map.getSize();
      const width = mapSize[0] * (this.range / 100);
      const tl = getRenderPixel(event, [width, 0]);
      const tr = getRenderPixel(event, [mapSize[0], 0]);
      const bl = getRenderPixel(event, [width, mapSize[1]]);
      const br = getRenderPixel(event, mapSize);

      ctx.save();
      ctx.beginPath();
      ctx.moveTo(tl[0], tl[1]);
      ctx.lineTo(bl[0], bl[1]);
      ctx.lineTo(br[0], br[1]);
      ctx.lineTo(tr[0], tr[1]);
      ctx.closePath();
      ctx.clip();
    },
    postrender(event) {
      const ctx = event.context;
      ctx.restore();
    },
    listener() {
      this.app.map.render();
    },
  },
};
</script>

<style scoped>
.times-slider {
  width: 100%;

  position: absolute;
  bottom: 0;
  z-index: 1;
  margin: 0;
  padding: 0;
  background: rgb(29, 39, 49);
  background: linear-gradient(
    0deg,
    rgba(29, 39, 49, 1) 5%,
    rgba(255, 255, 255, 0) 100%
  );
}

.slider {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 2px;
  background: rgba(255, 255, 255, 0.4);
  outline: none;
  opacity: 1;
  border: none;
  padding: 0;
  margin: 0;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 8px;
  height: 25px;
  background: #1d2731;
  cursor: pointer;
  border-radius: 3px;
  border: #fff solid 1px;
}

.slider::-moz-range-thumb {
  width: 5x;
  height: 25px;
  background: #1d2731;
  cursor: pointer;
}
</style>

