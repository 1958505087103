<template>
  <el-descriptions
    size="small"
    direction="horizontal"
    :column="1"
    :border="true"
  >
    <el-descriptions-item label="ID Sistema">
      {{ postData.mslink }}
    </el-descriptions-item>
    <el-descriptions-item label="Municício">
      {{ postData.municipioNome }}
    </el-descriptions-item>
    <el-descriptions-item label="ID Circuito Sec">
      {{ postData.circuitoSecundarioId }}
    </el-descriptions-item>
    <el-descriptions-item label="ID Energisa">
      {{ postData.companhiaId }}
    </el-descriptions-item>
    <el-descriptions-item label="Serviço">
      {{ postData.servicoColeta }}
    </el-descriptions-item>
    <el-descriptions-item label="Interpretação IP">
      {{ postData.indicadorIp }}
    </el-descriptions-item>
    <el-descriptions-item label="Interpretação Uso">
      {{ postData.indicadorUso }}
    </el-descriptions-item>
    <el-descriptions-item label="Endereço">
      {{ postData.endereco }}
    </el-descriptions-item>
    <el-descriptions-item label="Coordenada X">
      {{ postData.x }}
    </el-descriptions-item>
    <el-descriptions-item label="Coordenada Y">
      {{ postData.y }}
    </el-descriptions-item>
  </el-descriptions>
</template>

<script>
import { getPostData } from "../../services/agiltec.js";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import { Circle, Fill, Stroke, Style } from "ol/style";

export default {
  name: "PointInfoComponent",
  props: ["postId"],
  data() {
    return {
      postData: {},
      layer: null,
    };
  },
  mounted() {
    this.getData();
    this.layer = this.createLayer();
    this.app.map.addLayer(this.layer);
    this.emitter.on("point-info-closed", this.clearPoint);
    this.emitter.on("point-info-open", this.getData);
  },
  methods: {
    clearPoint() {
      setTimeout(() => {
        this.layer.getSource().clear();
      }, 2000);
    },
    getData() {
      if (this.postId) {
        getPostData(this.postId).then((response) => {
          this.postData = response.source;
          this.drawPoint();
        });
      }
    },
    createLayer() {
      const layer = new VectorLayer({
        source: new VectorSource({
          features: [],
        }),
        style: new Style({
          image: new Circle({
            radius: 14,
            stroke: new Stroke({ color: "cyan", width: 4 }),
            fill: new Fill({ color: "rgba(0,255,255,0.2)" }),
          }),
        }),
        zIndex: 99999,
      });
      return layer;
    },
    drawPoint() {
      const point = new Feature({
        geometry: new Point([this.postData.x, this.postData.y]),
      });

      this.layer.getSource().clear();
      this.layer.getSource().addFeature(point);
      this.app.map.getView().setCenter([this.postData.x, this.postData.y]);
      this.app.map.getView().setZoom(18);
    },
  },
  watch: {
    postId() {
      this.getData();
    },
  },
};
</script>

<style>
</style>