<template>
  <widget-component title="Pesquisa Histórica" width="400px" height="300px">
    <el-form
      label-position="right"
      label-width="100px"
      style="max-width: 460px"
    >
      <el-form-item label="Camada">
        <el-select
          v-model="layerId"
          placeholder="Selecione uma camada"
          filterable
        >
          <el-option-group
            v-for="group in options"
            :key="group.label"
            :label="group.label"
          >
            <el-option
              v-for="item in group.options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-option-group>
        </el-select>
      </el-form-item>
      <el-form-item label="Data Inicial">
        <el-date-picker
          v-model="initialDate"
          type="date"
          placeholder="Data Inicial"
          format="DD/MM/YYYY"
        />
      </el-form-item>
      <el-form-item label="Data Final">
        <el-date-picker
          v-model="finalDate"
          type="date"
          placeholder="Data Final"
          format="DD/MM/YYYY"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="process"> Adicionar </el-button>
      </el-form-item>
    </el-form>
  </widget-component>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import { createLayers } from "@/helpers/layers";
import WidgetComponent from "../WidgetComponent.vue";

export default {
  components: { WidgetComponent },
  data() {
    return {
      layerId: null,
      options: [],
      initialDate: null,
      finalDate: null,
      historyLayer: null,
    };
  },
  mounted() {
    this.options = this.getHistoricLayers();
  },
  methods: {
    getGroupsWithHistoricLayers() {
      return this.app.config.groups.filter((group) => {
        return group.layers.some((layer) => {
          return layer.history;
        });
      });
    },
    getHistoricLayers() {
      return this.getGroupsWithHistoricLayers().map((group) => {
        let validLayer = group.layers.filter((layer) => {
          return layer.history;
        });
        return {
          label: group.title,
          options: validLayer.map((layer) => {
            return {
              label: layer.title,
              value: layer.id,
            };
          }),
        };
      });
    },
    process() {
      this.createHistoryLayer();
      createLayers(this.app.config.groups);
      this.app.map.addLayer(this.historyLayer.ol);
      this.updateCQLFilter(this.historyLayer, this.initialDate, this.finalDate);
       this.emitter.emit("add-layer", this.historyLayer);
    },
    updateCQLFilter(layer, initialDate, finalDate) {
      const timeField = layer.timeField;
      const initialFilter = initialDate ? `${timeField} AFTER ${initialDate.toISOString()}` : '';
      const finalFilter = finalDate ? `${timeField} BEFORE ${finalDate.toISOString()}` : '';
      const andFilter = !!initialDate && !!finalDate ? " AND " : "";
      let cqlFilter = initialFilter + andFilter + finalFilter;
      layer.ol.getSource().updateParams({ CQL_FILTER: cqlFilter });
    },
    createHistoryLayer() {
      let layer = this.getLayerGroupById(this.layerId);
      this.historyLayer = { ...layer.history };
      this.historyLayer.id = uuidv4();
      this.historyLayer.visible = true;
      this.historyLayer.title = `${layer.title} ${this.getFormatedTimeRange()}`;
      this.historyLayer.initialDate = this.initialDate;
      this.historyLayer.finalDate = this.finalDate;
      this.createHistoryGroup([this.historyLayer]);
    },
    createHistoryGroup(layers) {
      let historyGroup = this.getGroupByTitle("Pesquisa Histórica");
      if (!historyGroup) {
        historyGroup = {
          title: "Pesquisa Histórica",
          layers: [],
        };
        this.app.config.groups.unshift(historyGroup);
      }
      historyGroup.layers = historyGroup.layers.concat(layers);
    },
    getFormatedTimeRange() {
      let initialDate = this.initialDate ? this.initialDate?.toLocaleDateString() : '';
      let finalDate = this.finalDate ? this.finalDate?.toLocaleDateString() : '';
      return `${initialDate} ${finalDate}`;
    },
    getLayerById(id) {
      let olLayer;
      this.app.config.groups.forEach((group) => {
        group.layers.forEach((layer) => {
          if (layer.id === id && layer.type == "wms") {
            olLayer = layer.ol;
          }
        });
      });
      return olLayer;
    },
    getLayerGroupById(id) {
      let extLayer;
      this.app.config.groups.forEach((group) => {
        group.layers.forEach((layer) => {
          if (layer.id === id && layer.type == "wms") {
            extLayer = layer;
          }
        });
      });
      return extLayer;
    },
    getGroupByTitle(title) {
      let extGroup;
      this.app.config.groups.forEach((group) => {
        if (group.title === title) {
          extGroup = group;
        }
      });
      return extGroup;
    },
  },
};
</script>

<style>
</style>