<template>
  <div>
    <h4>{{ title }}</h4>
    <div :id="id"></div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import ApexCharts from "apexcharts";
export default {
  name: "UsoMutuoComponent",
  components: {},
  props: ["data", "title"],
  data() {
    return {
      id: uuidv4(),
      options: {
        chart: {
          type: "pie",
          toolbar: {
            show: false,
          },
        },
        labels: ["Carregando dados..."],
        dataLabels: {
          formatter: function (value, { seriesIndex, w }) {
            return w.config.series[seriesIndex];
          },
        },
        tooltip: {
          y: {
            formatter: function (value, { config }) {
              const reducer = (accumulator, curr) => accumulator + curr;
              const total = config.series.reduce(reducer);
              return parseInt((value / total) * 1000) / 10 + "%";
            },
          },
        },
        series: [1],
      },
    };
  },
  mounted() {
    this.chart = new ApexCharts(
      document.getElementById(`${this.id}`),
      this.options
    );
    this.chart.render();
  },
  watch: {
    data: {
      handler() {
        console.log("data changed", this.data);
        this.chart.updateSeries([
          {
            data: this.data.serie,
          },
        ]);
        this.chart.updateOptions({
          series: this.data.serie,
          labels: this.data.labels,
        });
      },
      deep: true,
    },
  },
};
</script>

<style>
.el-dialog__body {
  padding-top: 0;
}
#apexchartsusomutuo svg {
  padding-top: 0 !important;
  transform: translate(0, -30px);
}
</style>
