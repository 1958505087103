<template>
  <img src="loading.gif" alt="" srcset="" />
</template>

<script>
export default {
  name: "MouseCoordinate",
};
</script>

<style scoped>
img {
  width: 100%;
  height: 100%;
}
</style>
