<template>
  <el-dialog
    v-model="show"
    :title="'Poste - ' + postId"
    width="90%"
    top="5vh"
    @closed="closed"
  >
    <div class="container">
      <div class="row pc">
        <PostImagesComponent :postId="postId" />
      </div>
      <div class="row">
        <el-tabs v-model="activeName">
          <el-tab-pane label="Dados do Ponto" name="first">
            <el-scrollbar height="600px">
              <PostInfoComponent :postId="postId" />
              <PostAnomalyComponent :postId="postId" />
            </el-scrollbar>
          </el-tab-pane>
          <el-tab-pane label="Dados do Compartilhamento" name="second">
            <el-scrollbar height="600px">
              <SharedPostComponent :postId="postId" />
            </el-scrollbar>
          </el-tab-pane>
          <el-tab-pane label="Dados de IP" name="third">
            <el-scrollbar height="600px">
              <IpInfoComponent :postId="postId" />
            </el-scrollbar>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import PostImagesComponent from "./PostImagesComponent.vue";
import PostInfoComponent from "./PostInfoComponent.vue";
import PostAnomalyComponent from "./PostAnomalyComponent.vue";
import SharedPostComponent from "./SharedPostComponent.vue";
import IpInfoComponent from "./IpInfoComponent.vue";
export default {
  name: "PointInfoComponent",
  components: {
    PostImagesComponent,
    PostInfoComponent,
    PostAnomalyComponent,
    SharedPostComponent,
    IpInfoComponent,
  },
  data: () => {
    return {
      activeName: "first",
      show: false,
      postId: null,
    };
  },
  methods: {
    open(postId) {
      this.postId = postId;
      this.show = true;
      this.emitter.emit("point-info-open");
    },
    closed() {
      this.emitter.emit("point-info-closed");
    },
  },
};
</script>

<style scoped>
.container {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
  height: 100%;
}
.container img {
  height: 100%;
  margin: 0;
}
.row {
  width: 48%;
  height: 70vh;
  padding: 0 10px;
}
.pc {
  padding-top: 6px;
}
</style>
<style>
.el-image {
  display: block;
  height: 100%;
  margin-bottom: 10px;
}
</style>