<template>
  <el-empty v-if="data.length == 0" description="Sem dados" />
  <el-tabs
    type="border-card"
    v-model="activeName"
    v-for="(ip, index) in data"
    v-bind:key="ip.mslink"
  >
    <el-tab-pane :label="'IP ' + (parseInt(index) + 1)" :name="index">
      <el-descriptions
        size="small"
        direction="horizontal"
        :column="1"
        :border="true"
      >
        <el-descriptions-item label="ID Sistema">
          {{ ip.mslink }}
        </el-descriptions-item>
        <el-descriptions-item label="Qtde. Lâmpadas">
          {{ ip.quantidadeLampadas }}
        </el-descriptions-item>
        <el-descriptions-item label="Potência (Watts)">
          {{ ip.potencia }}
        </el-descriptions-item>
        <el-descriptions-item label="Tipo Lâmpada">
          {{ ip.tipoLamapada }}
        </el-descriptions-item>
      </el-descriptions>
      <IpAnomalyComponent :ipId="ip.mslink" />
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import IpAnomalyComponent from "./IpAnomalyComponent.vue";
import { getPostIPData } from "../../services/agiltec";

export default {
  components: {
    // eslint-disable-next-line
    IpAnomalyComponent,
  },
  data() {
    return {
      activeName: "0",
      data: [],
    };
  },
  props: ["postId"],
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      getPostIPData(this.postId).then((response) => {
        this.data = response.source;
      });
    },
  },
  watch: {
    postId: function () {
      this.getData();
    },
  },
};
</script>

<style>
</style>