<template>
  <div class="icons">
    <ControlComponent
      tip="Controle de Camadas"
      icon="layers"
      :child="LayerSwitcher"
      type="widget"
    />
    <ControlComponent
      tip="Rotas Consumidores"
      icon="cluster"
      :child="ConsumerClusterComponent"
      type="widget"
    />
    <ControlComponent
      tip="Informações do Poste"
      icon="post"
      :child="MapPointInfoComponent"
      type="widget"
    />
    <ControlComponent
      tip="Informações da Árvore"
      icon="tree"
      :child="MapArvoreInfoComponent"
      type="widget"
    />
    <ControlComponent
      tip="Dashboard"
      icon="dashboard"
      :child="DashboardComponent"
      type="widget"
    />
    <ControlComponent
      tip="StreetView"
      icon="streetview"
      :child="StreetViewComponent"
      type="widget"
    />
    <ControlComponent
      tip="Pesquisa Espacial"
      icon="querybuilder"
      :child="QueryBuilderComponent"
      type="widget"
    />
    <ControlComponent
      tip="Pesquisa Histórca"
      icon="history"
      :child="TimeSeriesComponent"
      type="widget"
    />
    <ControlComponent
      tip="Swap"
      icon="swipe"
      :child="SwapLayerComponent"
      type="widget"
    />
    <ControlBarSeparatorComponent />
    <ControlComponent
      tip="Voltar para a posição inicial"
      icon="home"
      :child="GoToHomeComponent"
      type="button"
    />
    <ControlComponent
      tip="Aproximar"
      icon="plus"
      :child="ZoomInComponent"
      type="button"
    />
    <ControlComponent
      tip="Afastar"
      icon="minus"
      :child="ZoomOutComponent"
      type="button"
    />
  </div>
</template>

<script>
/* eslint-disable */
import { markRaw } from "vue";
import ControlComponent from "./ControlComponent.vue";
import ControlBarSeparatorComponent from "./ControlBarSeparatorComponent.vue";
import ZoomInComponent from "./map/ZoomInComponent.vue";
import ZoomOutComponent from "./map/ZoomOutComponent.vue";
import GoToHomeComponent from "./map/GoToHomeComponent.vue";
import LayerSwitcher from "./map/LayerSwitcherComponent.vue";
import StreetViewComponent from "./map/StreetViewComponent.vue";
import MapPointInfoComponent from "./map/MapPointInfoComponent.vue";
import DashboardComponent from "./dashboard/DashBoardComponent.vue";
import QueryBuilderComponent from "./querybuilder/QueryBuilderComponent.vue";
import TimeSeriesComponent from "./map/TimeSeriesComponent.vue";
import SwapLayerComponent from "./map/SwapLayerComponent.vue";
import ConsumerClusterComponent from "./consumercluster/ConsumerClusterComponent.vue";
import MapArvoreInfoComponent from "./map/MapArvoreInfoComponent.vue";

export default {
  name: "ControlBarComponent",
  components: {
    ControlComponent,
    ControlBarSeparatorComponent,
    ZoomInComponent,
    GoToHomeComponent,
    LayerSwitcher,
    StreetViewComponent,
    MapPointInfoComponent,
    DashboardComponent,
    QueryBuilderComponent,
    TimeSeriesComponent,
    SwapLayerComponent,
    ConsumerClusterComponent,
    MapArvoreInfoComponent
  },
  data() {
    return {
      ZoomInComponent: markRaw(ZoomInComponent),
      ZoomOutComponent: markRaw(ZoomOutComponent),
      GoToHomeComponent: markRaw(GoToHomeComponent),
      LayerSwitcher: markRaw(LayerSwitcher),
      StreetViewComponent: markRaw(StreetViewComponent),
      MapPointInfoComponent: markRaw(MapPointInfoComponent),
      DashboardComponent: markRaw(DashboardComponent),
      QueryBuilderComponent: markRaw(QueryBuilderComponent),
      TimeSeriesComponent: markRaw(TimeSeriesComponent),
      SwapLayerComponent: markRaw(SwapLayerComponent),
      ConsumerClusterComponent: markRaw(ConsumerClusterComponent),
      MapArvoreInfoComponent: markRaw(MapArvoreInfoComponent)
    };
  },
};
</script>

<style scoped>
.icons {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.icon {
  width: 1rem;
  height: 1rem;
  background-size: 1rem 1rem;
  padding: 1rem 0.2rem;
  margin: 1rem 0.2rem;
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
}
.icon:hover {
  cursor: pointer;
  background-color: #29323b;
  border-radius: 1rem;
}
.icon img {
  width: 100%;
  height: 100%;
}
</style>
