<template>
  <div>
    <h4>Ocupante</h4>
    <div :id="id"></div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import ApexCharts from "apexcharts";

export default {
  name: "UsoMutuoComponent",
  components: {},
  props: ["data"],
  data() {
    return {
      chart: null,
      id: uuidv4(),
      options: {
        chart: {
          id: "usomutuo",
          type: "treemap",
          height: 200,
          toolbar: {
            show: false,
          },
        },
        noData: {
          text: "Sem dados",
        },
        plotOptions: {
          treemap: {
            distributed: true,
            enableShades: false,
          },
        },
        colors: ["#333"],

        series: [
          {
            data: [
              {
                x: "Carregando dados...",
                y: 1,
              },
            ],
          },
        ],
      },
    };
  },
  mounted() {
    this.chart = new ApexCharts(
      document.getElementById(`${this.id}`),
      this.options
    );
    this.chart.render();
  },
  watch: {
    data: {
      handler() {
        this.chart.updateSeries([
          {
            data: this.data.serie,
          },
        ]);
        this.chart.updateOptions({
          colors: this.data.colors,
        });
      },
      deep: true,
    },
  },
};
</script>
<style>
.el-dialog__body {
  padding-top: 0;
}
#apexchartsusomutuo svg {
  padding-top: 0 !important;
  transform: translate(0, -30px);
}
</style>
