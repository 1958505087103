<template>
  <WidgetComponent title="Rotas Consumidores" width="500px">
    <!-- <form> -->
    <el-form :model="form" label-width="200px">
      <el-form-item label="Dados dos Consumidores">
        <input
          type="file"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          ref="xlsx"
        />
      </el-form-item>
      <el-form-item label="Número de Clusters">
        <el-input-number v-model="num" :min="1" :max="1000" />
      </el-form-item>
      <el-form-item label="Diâmetro Máx. do Cluster (m)">
        <el-input-number v-model="diam" :min="500" :max="10000" />
      </el-form-item>

      <br />
      <el-button class="ml-3" type="info" @click="submitUpload">
        Enviar
      </el-button>
    </el-form>
    <!-- </form> -->
  </WidgetComponent>
</template>

<script>
import WidgetComponent from "../WidgetComponent.vue";
export default {
  components: {
    WidgetComponent,
  },
  data() {
    return {
      num: 200,
      diam: 500,
      files: null,
      form: {
        file: null,
      },
    };
  },
  methods: {
    submitUpload() {
      const file = this.$refs.xlsx.files[0];
      //console.log(this.files);
      const xhr = new XMLHttpRequest();
      const formData = new FormData();
      formData.append("xlsx", file);
      formData.append("count", this.num);
      xhr.open("POST", "http://187.72.127.17:3000/consumer/upload");
      xhr.send(formData);
      xhr.onload = () => {
        if (xhr.status === 200) {
          console.log(xhr.response);
        } else {
          console.error(xhr.response);
        }
      };
    },
  },
};
</script>

<style scoped>
.w-100 {
  width: 100% !important;
}
</style>