<template>
  <div>
    <el-tooltip class="box-item" effect="dark" :content="tip" placement="right">
      <div class="icon" :class="{ active: open }">
        <img @click="execute" :src="iconUrl" alt="" srcset="" />
      </div>
    </el-tooltip>
    <Teleport to="body">
      <div v-if="!hasUi || (hasUi && open)">
        <component
          @close="close"
          :is="child"
          :ref="componentId"
          :componentId="componentId"
        ></component>
      </div>
    </Teleport>
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
export default {
  name: "ControlComponent",
  props: ["tip", "icon", "child", "type"],
  data() {
    return {
      open: false,
      componentId: uuidv4(),
    };
  },
  computed: {
    iconUrl() {
      return require(`../assets/icons/${this.icon}.svg`);
    },
    hasUi() {
      return this.type == "widget";
    },
  },
  methods: {
    execute() {
      this.open = !this.open;
      if (!this.hasUi) {
        this.$refs[this.componentId].execute();
      }
    },
    close() {
      this.open = false;
      this.emitter.emit("close-widget", this.componentId);
    },
  },
};
</script>

<style scoped>
.icon {
  width: 2rem;
  height: 2rem;
  background-size: 2rem 2rem;
  padding: 0.2rem;
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
  margin-top: 0.5rem;
}
.icon:hover,
.active {
  cursor: pointer;
  /* background-color: #29323b; */
  border-radius: 1rem;
  filter: invert(20%) sepia(40%) saturate(2878%) hue-rotate(180deg)
    brightness(104%) contrast(97%);
}
.icon img {
  width: 100%;
  height: 100%;
}
</style>
