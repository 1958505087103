<template>
  <div>
    <div class="search">
      <input
        ref="searchInput"
        type="search"
        placeholder="Encontre locais, postes..."
        v-model="terms"
        @input="search"
      />
    </div>
    <div class="close"></div>
    <Transition>
      <div class="result" v-if="showResults">
        <el-tabs v-model="activeName" class="demo-tabs">
          <el-tab-pane label="Poste" name="first">
            <div v-if="posts.length == 0" class="search-result">
              Nenhum poste encontrado.
            </div>
            <div
              class="search-result"
              v-for="item in posts"
              :key="item.mslink"
              @click="getPostData(item)"
            >
              Poste {{ item.mslink }}
            </div>
          </el-tab-pane>
          <el-tab-pane label="Locais" name="second">
            <div
              class="search-result"
              v-for="item in places"
              :key="item.place_id"
              @click="getGooglePlace(item)"
            >
              {{ item.description }}
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </Transition>
  </div>
</template>

<script>
import PointInfoComponent from "../data/PointInfoComponent.vue";
import { transform } from "ol/proj";
import { transformExtent } from "ol/proj";
import { getPostData } from "../../services/agiltec.js";
export default {
  name: "GeoCodingComponent",
  //eslint-disable-next-line
  components: { PointInfoComponent },
  data() {
    return {
      terms: null,
      places: [],
      posts: [],
      autocomplete: null,
      showResults: false,
      searchTimeOut: null,
      activeName: "first",
      postId: "240044160",
      showPostData: false,
    };
  },

  async mounted() {
    // eslint-disable-next-line
    const { AutocompleteService } = await google.maps.importLibrary("places");
    this.autocompleteService = new AutocompleteService();
    // eslint-disable-next-line
    this.placesService = new google.maps.places.PlacesService(
      document.createElement("div")
    );
  },
  methods: {
    search() {
      if (!this.terms) {
        this.closeResults();
        return;
      }

      this.searchTimeOut = 700;
      setTimeout(this.getSearchData, this.searchTimeOut);
    },
    getSearchData() {
      const input = this.terms;
      this.autocompleteService.getPlacePredictions(
        { input },
        this.showGoogleSearchResults
      );
      if (input && !isNaN(input)) {
        try {
          getPostData(input).then((response) => {
            if (response.success) {
              this.posts = [response.source];
              this.showResults = true;
            } else {
              this.posts = [];
            }
          });
        } catch (error) {
          this.posts = [];
        }
      }
    },
    showGoogleSearchResults(result) {
      this.showResults = true;
      this.places = result;
    },
    getGooglePlace(data) {
      let request = {
        placeId: data.place_id,
      };
      this.placesService.getDetails(request, this.showGooglePlacesResults);
    },
    showGooglePlacesResults(place, status) {
      // eslint-disable-next-line
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        let viewPort = place.geometry.viewport;
        let location = place.geometry.location;
        let bounds = [
          viewPort.getSouthWest().lng(),
          viewPort.getSouthWest().lat(),
          viewPort.getSouthWest().lng(),
          viewPort.getNorthEast().lat(),
          viewPort.getNorthEast().lng(),
          viewPort.getNorthEast().lat(),
          viewPort.getNorthEast().lng(),
          viewPort.getSouthWest().lat(),
        ];
        const mapProjection = this.app.map.getView().getProjection().getCode();
        let extent = transformExtent(bounds, "EPSG:4326", mapProjection);
        let center = transform(
          [location.lng(), location.lat()],
          "EPSG:4326",
          mapProjection
        );
        this.app.map.getView().fit(extent);
        this.app.map.getView().setCenter(center);
        this.closeResults();
      }
    },
    getPostData(post) {
      this.$root.$refs.postDataView.open(post.mslink);
      this.closeResults();
    },
    closeResults() {
      this.showResults = false;
      this.terms = null;
      this.places = [];
    },
  },
};
</script>

<style scoped>
.search-result {
  padding: 12px 18px;
  cursor: pointer;
  font-size: 14px;
  color: var(--el-text-color-regular);
  border-bottom: 1px solid var(--el-border-color-light);
}

.search {
  width: 300px;
  height: 32px;
  position: absolute;
  top: 12px;
  left: 64px;
  z-index: 100;
}
.search input {
  width: 100%;
  height: 100%;
  border: 1px solid rgba(0, 0, 0, 0.5);
  outline: none;
  border-radius: 16px;
  padding: 0 16px;
  background: rgba(255, 255, 255, 0.3);
}
.search input:focus {
  background: rgba(255, 255, 255, 0.8);
}
.search input::placeholder {
  color: #222;
}
.result {
  width: 360px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 50px;
  z-index: 99;
  background: rgba(255, 255, 255, 0.99);
  padding-top: 54px;
}
.result > * {
  background: none !important;
}
.v-enter-active,
.v-leave-active {
  transition: opacity 0.2s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
<style>
.el-tabs__nav {
  transform: translateX(18px) !important;
  color: #999;
}
.el-tabs__item {
  color: #999;
  font-size: 14px;
}
</style>