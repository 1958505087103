<template>
  <div>
    <h3>Galeria de Imagens</h3>
    <el-scrollbar height="600px">
      <el-space spacer=" " wrap>
        <el-image
          :src="item.url"
          :preview-src-list="previews"
          fit="cover"
          v-for="(item, i) in images"
          :key="item.id"
          :name="item.id + ''"
          :initial-index="i"
          class="image-slot"
        >
          <template #placeholder>
            <div class="image-slot">Carregando<span class="dot">...</span></div>
          </template>
        </el-image>
      </el-space>
    </el-scrollbar>
  </div>
</template>

<script>
import { listImagesById } from "../../services/agiltec.js";

export default {
  name: "PostImagesComponent",
  props: ["postId"],
  data() {
    return {
      images: [],
      previews: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      listImagesById(this.postId).then((response) => {
        this.images = response;
        this.previews = response.map((item) => {
          return item.url;
        });
      });
    },
  },
  watch: {
    postId() {
      this.getData();
    },
  },
};
</script>

<style>
.image-slot {
  width: 150px !important;
  height: 150px !important;
  background-color: #fff;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.carousel-img-container {
  width: 100%;
  height: 100%;
  background-size: cover;
}
.carousel-img-blur {
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(10px);
}
.el-carousel__item h3 {
  color: #475669;
  opacity: 0.75;
  line-height: 580px;
  margin: 0;
  text-align: center;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
</style>