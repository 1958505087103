<template>
<div></div>
</template>
<script>
import { transform } from "ol/proj";
export default {
    name: "GoToHomeComponent",
    methods: {
        execute() {
            const center = transform(this.app.config.center, "EPSG:4326", "EPSG:3857");
            this.app.map.getView().setZoom(this.app.config.zoom);
            this.app.map.getView().setCenter(center);
        }
    }
}
</script>