<template>
  <WidgetComponent title="StreetView" height="500px" width="500px">
    <div v-show="showSv" class="streetview" id="streetview"></div>
    <div v-show="!showSv" class="streetview">
      <el-result
        style="margin-top: 60px"
        title="Clique sobre o mapa para obter um panorama"
      >
      </el-result>
    </div>
  </WidgetComponent>
</template>

<script>
import WidgetComponent from "../WidgetComponent.vue";
import { transform } from "ol/proj";
import { Circle, Fill, Stroke, Style } from "ol/style";
import { Vector as VectorLayer } from "ol/layer";
import Feature from "ol/Feature";
import VectorSource from "ol/source/Vector";
import { Point, Polygon } from "ol/geom";

export default {
  name: "StreetViewComponent",
  components: {
    WidgetComponent,
  },
  props: ["componentId"],
  data() {
    return {
      panorama: null,
      isMapClick: false,
      clickPosition: null,
      showSv: false,
    };
  },
  mounted() {
    const mapContainer = document.getElementById("map");
    mapContainer.style.cursor = "crosshair";

    const gCenter = { lat: 0, lng: 0 };
    // eslint-disable-next-line
    this.panorama = new google.maps.StreetViewPanorama(
      document.getElementById("streetview"),
      {
        position: gCenter,
      }
    );

    this.createLayer();
    this.registerEvents();
  },
  methods: {
    createLayer() {
      this.streetViewLayer = new VectorLayer({
        source: new VectorSource(),
      });
      this.streetViewLayer.setZIndex(99999);
      this.app.map.addLayer(this.streetViewLayer);

      this.streetViewPanoramaStyle = new Style({
        image: new Circle({
          radius: 3,
          fill: new Fill({
            color: "rgb(255,255,0)",
          }),
          stroke: new Stroke({
            color: "rgb(255,255,0)",
            width: 1,
          }),
        }),
        fill: new Fill({
          color: "rgba(255,255,0,.5)",
        }),
        stroke: new Stroke({
          color: "rgb(255,255,0)",
          width: 2,
        }),
      });
    },
    onClose(componentId) {
      if (componentId == this.componentId) {
        const mapContainer = document.getElementById("map");
        mapContainer.style.cursor = "unset";
        this.streetViewLayer.getSource().clear();
        this.panorama = null;
        this.app.map.un("click", this.mapClickEvent);
        this.emitter.off("close-widget", this.onClose);
      }
    },
    mapClickEvent(evt) {
      let coord = evt.coordinate;
      let pos = transform(
        coord,
        this.app.map.getView().getProjection().getCode(),
        "EPSG:4326"
      );
      this.panorama.setPosition({ lat: pos[1], lng: pos[0] });

      this.isMapClick = true;
      this.clickPosition = coord;
      this.showSv = true;
    },
    drawPanoramaPosition() {
      let pos = [this.panorama.position.lng(), this.panorama.position.lat()];
      pos = transform(
        pos,
        "EPSG:4326",
        this.app.map.getView().getProjection().getCode()
      );

      let triangleFeature = this.getPanoramaMapView(pos, this.panorama);
      let pointFeature = new Feature({
        geometry: new Point(pos),
      });

      pointFeature.setStyle(this.streetViewPanoramaStyle);
      triangleFeature.setStyle(this.streetViewPanoramaStyle);

      this.streetViewLayer.getSource().clear();
      this.streetViewLayer.getSource().addFeature(pointFeature);
      this.streetViewLayer.getSource().addFeature(triangleFeature);

      if (this.isMapClick) {
        this.isMapClick = false;

        let p1 = [this.panorama.position.lng(), this.panorama.position.lat()];
        let pt = transform(
          this.clickPosition,
          this.app.map.getView().getProjection().getCode(),
          "EPSG:3857"
        );
        p1 = transform(p1, "EPSG:4326", "EPSG:3857");

        let heading =
          (Math.atan2(pt[0] - p1[0], pt[1] - p1[1]) * 180) / Math.PI;

        this.panorama.setPov({
          heading: heading,
          pitch: this.panorama.pov.pitch,
        });
      }
    },
    getPanoramaMapView() {
      let mapProjection = this.app.map.getView().getProjection();
      let p1 = [this.panorama.position.lng(), this.panorama.position.lat()];
      p1 = transform(p1, "EPSG:4326", "EPSG:3857");

      let radius = 15;
      let zoom = this.panorama.pov.zoom;
      let fov = Math.PI / Math.pow(2, zoom);
      let azimuth = (this.panorama.pov.heading * Math.PI) / 180;
      let p2 = [];
      let p3 = [];

      p2[0] = p1[0] + radius * Math.sin(azimuth + fov / 2);
      p2[1] = p1[1] + radius * Math.cos(azimuth + fov / 2);

      p3[0] = p1[0] + radius * Math.sin(azimuth - fov / 2);
      p3[1] = p1[1] + radius * Math.cos(azimuth - fov / 2);

      p1 = transform(p1, "EPSG:3857", mapProjection);
      p2 = transform(p2, "EPSG:3857", mapProjection);
      p3 = transform(p3, "EPSG:3857", mapProjection);

      let polygon = new Polygon([[p1, p2, p3]]);

      return new Feature({
        geometry: polygon,
      });
    },
    registerEvents() {
      this.emitter.on("close-widget", this.onClose);

      this.panorama.addListener("position_changed", () => {
        this.drawPanoramaPosition();
      });

      this.panorama.addListener("pov_changed", () => {
        this.drawPanoramaPosition();
      });

      this.app.map.on("click", this.mapClickEvent);
    },
  },
};
</script>

<style scoped>
.streetview {
  height: 410px;
  width: 460px;
}
</style>
