<template>
  <el-dialog title="Dashboard" v-model="show" width="100%" top="5vh">
    <el-space spacer=" ">
      <div class="grid-content" style="">
        <el-switch
          v-model="isByCity"
          class="mb-2"
          active-color="#787878"
          inactive-color="#787878"
          active-text="Município"
          inactive-text="Companhia"
          @change="changeDasboardType"
        />
      </div>
      <div class="grid-content">
        <el-select
          v-model="municipio"
          filterable
          placeholder="Selecione..."
          v-show="isByCity"
          @change="getDataMunicipio"
        >
          <el-option
            v-for="item in municipios"
            :key="item.id"
            :label="item.nome"
            :value="item.id"
          />
        </el-select>
      </div>
    </el-space>
    <TotalComponent
      :totalIp="totalIp"
      :totalPontos="totalPontos"
      :totalSemIp="totalSemIp"
      :totalUsoCompartilhado="totalUsoCompartilhado"
    />
    <UsoMutuoComponent v-bind:data="usoMutuo" />
    <el-row :gutter="20">
      <el-col :span="6">
        <div class="grid-content bg-purple">
          <PieChartComponent
            ref="vaporMercurio"
            title="Vapor de Mercúrio"
            v-bind:data="vaporMercurio"
          />
        </div>
      </el-col>
      <el-col :span="6">
        <div class="grid-content bg-purple">
          <PieChartComponent
            ref="vaporSodio"
            title="Vapor de Sódio"
            v-bind:data="vaporSodio"
          />
        </div>
      </el-col>
      <el-col :span="6">
        <div class="grid-content bg-purple">
          <PieChartComponent ref="led" title="LED" v-bind:data="led" />
        </div>
      </el-col>
      <el-col :span="6">
        <div class="grid-content bg-purple">
          <PieChartComponent
            ref="incandescente"
            title="Incandescente"
            v-bind:data="incandescente"
          />
        </div>
      </el-col>
    </el-row>
    <!--
    <PieChartComponent v-bind:data="vaporSodio" /> -->
  </el-dialog>
</template>

<script>
import TotalComponent from "./TotalComponent.vue";
import UsoMutuoComponent from "./UsoMutuoComponent.vue";
import PieChartComponent from "./PieChartComponent.vue";
import {
  getMunicipiosList,
  getTotalPontos,
  getTotalUsoCompartilhado,
  getTotalIp,
  getTotalSemIp,
  getOcupanteUsoMutuo,
  getVaporMercurio,
  getVaporSodio,
  getLed,
  getMultiVaporMercurio,
  getTotalPontosMunicipio,
  getTotalUsoCompartilhadoMunicipio,
  getTotalIpMunicipio,
  getTotalSemIpMunicipio,
  getVaporSodioMunicipio,
  getLedMunicipio,
  getTipoOcupanteMunicipio,
  getVaporMercurioMunicipio,
  getMultiVaporMercurioMunicipio,
} from "../../services/agiltec.js";
export default {
  name: "DashBoardComponent",
  props: ["componentId"],
  components: {
    TotalComponent,
    UsoMutuoComponent,
    PieChartComponent,
  },
  data() {
    return {
      show: true,
      isByCity: false,
      municipios: [],
      municipio: null,
      totalIp: 0,
      totalSemIp: 0,
      totalPontos: 0,
      totalUsoCompartilhado: 0,
      usoMutuo: {
        colors: ["#fff"],
        serie: [
          {
            name: "Carregando dados...",
            data: [1],
          },
        ],
      },
      vaporMercurio: {
        labels: ["Carregando dados..."],
        serie: [1],
      },
      vaporSodio: {
        labels: [],
        serie: [],
      },
      led: {
        labels: [],
        serie: [],
      },
      incandescente: {
        labels: [],
        serie: [],
      },
    };
  },
  mounted() {
    this.getMunicipios();
    this.getDataCompanhia();
  },
  methods: {
    changeDasboardType() {
      if (this.isByCity && this.municipio) {
        this.getDataMunicipio();
      } else {
        this.getDataCompanhia();
      }
    },
    getMunicipios() {
      getMunicipiosList().then((response) => {
        this.municipios = response.source;
      });
    },
    getDataCompanhia() {
      getTotalPontos().then((response) => {
        this.totalPontos = response.source[0].total;
      });
      getTotalUsoCompartilhado().then((response) => {
        this.totalUsoCompartilhado = response.source[0].total;
      });
      getTotalIp().then((response) => {
        this.totalIp = response.source[0].total;
      });
      getTotalSemIp().then((response) => {
        this.totalSemIp = response.source[0].total;
      });
      getOcupanteUsoMutuo().then((response) => {
        this.usoMutuo.serie = response.source.map((item) => {
          return { x: item.descricao, y: item.total };
        });
        this.usoMutuo.colors = response.source.map(() => {
          return this.randomColor();
        });
      });
      getVaporMercurio().then((response) => {
        this.vaporMercurio.labels = response.source.map((item) => {
          return item.potencia + "W";
        });
        this.vaporMercurio.serie = response.source.map((item) => {
          return item.total;
        });
      });
      getVaporSodio().then((response) => {
        this.vaporSodio.labels = response.source.map((item) => {
          return item.potencia + "W";
        });
        this.vaporSodio.serie = response.source.map((item) => {
          return item.total;
        });
      });
      getLed().then((response) => {
        this.led.labels = response.source.map((item) => {
          return item.potencia + "W";
        });
        this.led.serie = response.source.map((item) => {
          return item.total;
        });
      });
      getMultiVaporMercurio().then((response) => {
        this.incandescente.labels = response.source.map((item) => {
          return item.potencia + "W";
        });
        this.incandescente.serie = response.source.map((item) => {
          return item.total;
        });
      });
    },
    getDataMunicipio() {
      getTotalPontosMunicipio(this.municipio).then((response) => {
        this.totalPontos = response.source[0].total;
      });
      getTotalUsoCompartilhadoMunicipio(this.municipio).then((response) => {
        this.totalUsoCompartilhado = response.source[0].total;
      });
      getTotalIpMunicipio(this.municipio).then((response) => {
        this.totalIp = response.source[0].total;
      });
      getTotalSemIpMunicipio(this.municipio).then((response) => {
        this.totalSemIp = response.source[0].total;
      });
      getTipoOcupanteMunicipio(this.municipio).then((response) => {
        this.usoMutuo.serie = response.source.map((item) => {
          return { x: item.descricao, y: item.total };
        });
        this.usoMutuo.colors = response.source.map(() => {
          return this.randomColor();
        });
      });
      getVaporMercurioMunicipio(this.municipio).then((response) => {
        this.vaporMercurio.labels = response.source.map((item) => {
          return item.potencia + "W";
        });
        this.vaporMercurio.serie = response.source.map((item) => {
          return item.total;
        });
      });
      getVaporSodioMunicipio(this.municipio).then((response) => {
        this.vaporSodio.labels = response.source.map((item) => {
          return item.potencia + "W";
        });
        this.vaporSodio.serie = response.source.map((item) => {
          return item.total;
        });
      });
      getLedMunicipio(this.municipio).then((response) => {
        this.led.labels = response.source.map((item) => {
          return item.potencia + "W";
        });
        this.led.serie = response.source.map((item) => {
          return item.total;
        });
      });
      getMultiVaporMercurioMunicipio(this.municipio).then((response) => {
        this.incandescente.labels = response.source.map((item) => {
          return item.potencia + "W";
        });
        this.incandescente.serie = response.source.map((item) => {
          return item.total;
        });
      });
    },
    randomColor() {
      var letters = "23456789ABCD";
      var color = "#";
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 12)];
      }
      return color;
    },
  },
};
</script>

<style>
.bg-purple {
  background-color: #7e57c200;
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
</style>