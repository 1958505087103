<template>
  <div>
    <el-table
      :data="shareData"
      style="width: 100%"
      empty-text="Sem dados"
      :border="true"
    >
      <el-table-column prop="quantidade" label="Quantidade" width="100" />
      <el-table-column prop="tipo" label="Empresa Ocupante" />
    </el-table>
  </div>
</template>

<script>
import { getPostShareData } from "../../services/agiltec";
export default {
  props: ["postId"],
  data() {
    return {
      shareData: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      getPostShareData(this.postId).then((response) => {
        this.shareData = response.source;
      });
    },
  },
  watch: {
    postId() {
      this.getData();
    },
  },
};
</script>

<style>
</style>