<template>
  <WidgetComponent title="Informações do Poste" width="500px">
    <el-result :title="msg"> </el-result>
  </WidgetComponent>
</template>

<script>
import WidgetComponent from "../WidgetComponent.vue";
import { getPosteByCooordinates } from "../../services/agiltec.js";

export default {
  name: "PointInfoComponent",
  components: {
    WidgetComponent,
  },
  props: ["componentId"],
  data() {
    return {
      postId: null,
      showPostData: false,
      msg: "Clique sobre o mapa para obter informações de um poste.",
    };
  },
  mounted() {
    const mapContainer = document.getElementById("map");
    mapContainer.style.cursor = "crosshair";

    this.registerEvents();
  },
  methods: {
    mapClickEvent(evt) {
      this.msg = "Buscando informações do poste...";
      getPosteByCooordinates(evt.coordinate).then((response) => {
        if (response.features.length == 0) {
          this.msg = "Nenhum poste encontrado.";
          setTimeout(() => {
            this.msg =
              "Clique sobre o mapa para obter informações de um poste.";
          }, 3000);
          return;
        }
        this.postId = response.features[0].properties.mslink;
        this.showPostData = true;

        this.$root.$refs.postDataView.open(
          response.features[0].properties.mslink
        );
        this.msg = "Clique sobre o mapa para obter informações de um poste.";
      });
    },
    onCloseEvt(componentId) {
      if (componentId == this.componentId) {
        const mapContainer = document.getElementById("map");
        mapContainer.style.cursor = "unset";
        this.app.map.un("click", this.mapClickEvent);
        this.emitter.off("close-widget", this.onCloseEvt);
      }
    },
    registerEvents() {
      this.emitter.on("close-widget", this.onCloseEvt);
      this.app.map.on("click", this.mapClickEvent);
    },
  },
};
</script>

<style scoped>
</style>
