<template>
  <WidgetComponent
    title="Pesquisa Temática"
    width="800px"
    :height="400"
    left="unset"
    right="0"
    enableCollapse="true"
  >
    <el-tabs
      type="border-card"
      v-model="activeTab"
      @tab-change="handleTabChange"
    >
      <el-tab-pane
        v-for="query in queries"
        :key="query.name"
        :name="query.name"
        :label="query.title"
      >
        <el-row class="row-bg">
          <el-form label-position="right" label-width="125px">
            <div v-for="filter in filters" :key="filter.name">
              <el-form-item :label="filter.title">
                <el-select
                  v-model="filter.selected"
                  filterable
                  placeholder="Selecione..."
                  :ref="'qb_select_' + filter.name"
                >
                  <el-option
                    v-for="item in filter.data"
                    :key="item.value"
                    :label="item.title"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </div>
            <el-form-item label="Simbologia">
              <el-color-picker v-model="color" show-alpha />
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="addRule">
                Adicionar regra
              </el-button>
              <el-button
                type="text"
                :disabled="tableData.length == 0"
                @click="removeAllRules"
                link
              >
                Remover todas as regras
              </el-button>
            </el-form-item>
          </el-form>
        </el-row>

        <el-table :data="tableData" style="width: 100%">
          <el-table-column
            v-for="(filter, index) in filters"
            :key="index"
            :label="filter.title"
            :prop="filter.name"
            class-name="table-column"
          />
          <el-table-column label="Cor" width="60px">
            <template #default="scope">
              <div
                class="color-preview"
                :style="`background: ${scope.row.color}`"
              ></div>
            </template>
          </el-table-column>
          <el-table-column label="Qntd." width="70px">
            <template #default="scope">
              <div class="loader" v-if="!scope.row.isLoaded">
                <img src="loading.gif" alt="" srcset="" />
              </div>
              {{ scope.row.count }}
            </template>
          </el-table-column>
          <el-table-column label="" width="90px">
            <template #default="scope">
              <el-button
                @click="removeRule(scope.row, scope.$index)"
                type="text"
                size="small"
                >Remover</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
    </el-tabs>
  </WidgetComponent>
</template>

<script>
import WidgetComponent from "../WidgetComponent.vue";
import GeoJSON from "ol/format/GeoJSON";
import { Circle as CircleStyle, Fill, Stroke, Style } from "ol/style";
import { Vector as VectorSource } from "ol/source";
import { Vector as VectorLayer } from "ol/layer";
import { ElMessageBox } from "element-plus";
import { v4 as uuidv4 } from "uuid";
import { createEmpty } from "ol/extent";
import LayerGroup from "ol/layer/Group";
import { extend as getExtent } from "ol/extent";
import { isEmpty as isExtentEmpty } from "ol/extent";

let layerGroup = new LayerGroup();

export default {
  components: {
    WidgetComponent,
  },
  data() {
    return {
      curruentQuery: this.app.config.querybuilder[0],
      queries: [],
      filters: [],
      tableData: [],
      color: this.randomColor(),
      opacity: 1,
      zIndex: 2000,
      activeTab: this.app.config.querybuilder[0].name,
      queryLayersExtent: createEmpty(),
    };
  },
  mounted() {
    this.queries = this.app.config.querybuilder;
    this.filters = [...this.curruentQuery.filters];
    this.activeTab = this.curruentQuery.name;
    this.listFeaturesTypes();
    this.removeHiddenLayers();

    this.app.map.addLayer(layerGroup);
  },
  beforeUnmount() {
    this.clearAllLayers();
    this.app.map.removeLayer(layerGroup);
  },
  methods: {
    handleTabChange(tab) {
      if (tab == this.curruentQuery.name || this.tableData.length == 0) {
        this.reloadFilters(tab);
      } else {
        ElMessageBox.confirm("Os dados atuais serão perdidos.", {
          confirmButtonText: "Continuar",
          cancelButtonText: "Cancelar",
          type: "warning",
        })
          .then(() => {
            this.clearAllLayers();
            this.reloadFilters(tab);
          })
          .catch(() => {
            this.activeTab = this.curruentQuery.name;
          });
      }
    },
    reloadFilters(tab) {
      this.curruentQuery = this.queries.find((q) => q.name === tab);
      this.filters = [...this.curruentQuery.filters];
      this.activeTab = this.curruentQuery.name;
      this.listFeaturesTypes();
    },
    listFeaturesTypes() {
      this.filters.forEach((filter) => {
        this.getFeatureTypes(filter);
      });
    },
    getFeatureTypes(filter) {
      this.loading++;
      fetch(filter.service)
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          this.loading--;
          filter.data = data.features.map((feature) => {
            return {
              title: feature.properties[filter.label],
              value: feature.properties[filter.value],
            };
          });
        });
    },
    randomColor() {
      var letters = "23456789ABCD";
      var color = "#";
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 12)];
      }
      return color;
    },
    addRule() {
      let data = {};
      this.filters.forEach((filter) => {
        data[filter.name + "_value"] = filter.selected;
        data[filter.name] = filter.data.find(
          (element) => element.value == filter.selected
        ).title;
      });
      data.color = this.color;
      //
      this.tableData.push(data);
      this.clearForm();
      this.getData();
    },
    removeRule(rule, index) {
      ElMessageBox.confirm("Deseja remover a regra?", {
        confirmButtonText: "Remover",
        cancelButtonText: "Cancelar",
        type: "warning",
      })
        .then(() => {
          layerGroup.getLayers().removeAt(index);
          this.tableData = this.tableData.filter((r) => r.id != rule.id);
          this.getData();
        })
        .catch(() => {});
    },
    clearForm() {
      this.color = this.randomColor();
      this.filters.forEach((filter) => {
        if (filter.fixed) return;
        filter.selected = "";
      });
    },
    getData() {
      this.tableData.forEach((row) => {
        if (row.isLoaded) return;
        let url = this.curruentQuery.service;
        this.filters.forEach((filter) => {
          url = url.replace(`{{${filter.name}}}`, row[filter.name + "_value"]);
        });
        fetch(url)
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            row.isLoaded = true;
            row.count = data.totalFeatures;
            row.data = data;
            row.id = uuidv4();
            this.draw(row);
          });
      });
    },
    draw(featureClass) {
      const vectorSource = new VectorSource({
        features: new GeoJSON().readFeatures(featureClass.data),
      });
      const vectorLayer = new VectorLayer({
        source: vectorSource,
        style: new Style({
          image: new CircleStyle({
            radius: 10,
            fill: new Fill({
              color: featureClass.color,
            }),
            stroke: new Stroke({
              color: featureClass.color,
            }),
          }),
        }),
        properties: {
          component: "querybuilder",
          id: featureClass.id,
        },
        zIndex: this.zIndex++,
      });

      layerGroup.getLayers().push(vectorLayer);
      this.fitToExtent();
    },
    fitToExtent() {
      let extent = createEmpty();
      layerGroup.getLayers().forEach((layer) => {
        if (layer.getSource().getFeatures().length > 0) {
          getExtent(extent, layer.getSource().getExtent());
        }
      });

      if (!isExtentEmpty(extent)) {
        this.app.map.getView().fit(extent, {
          padding: [150, 150, 150, 150],
        });
      }
    },
    removeAllRules() {
      ElMessageBox.confirm("Deseja remover todas as regras?", {
        confirmButtonText: "Remover",
        cancelButtonText: "Cancelar",
        type: "warning",
      })
        .then(() => {
          this.clearAllLayers();
        })
        .catch(() => {});
    },
    clearAllLayers() {
      this.tableData = [];
      layerGroup.getLayers().clear();
    },
    removeHiddenLayers() {
      let hiddenLayers = this.curruentQuery.hiddenLayers;
      this.app.config.groups.forEach((group) => {
        group.layers.forEach((layer) => {
          if (hiddenLayers.includes(layer.title)) {
            layer.visible = false;
            this.app.map.removeLayer(layer.ol);
          }
        });
      });
    },
  },
};
</script>

<style scoped>
.color-preview {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: inline-block;
}
.row-bg {
  padding-top: 1rem;
}

.loader img {
  width: 20px;
  height: 20px;
  background: var(--el-color-primary);
  border-radius: 50%;
}

.slider-demo-block {
  display: flex;
  align-items: center;
  width: 400px;
}
.slider-demo-block .el-slider {
  margin-top: 0;
  margin-left: 12px;
}
.slider-demo-block .demonstration {
  font-size: 14px;
  color: var(--el-text-color-secondary);
  line-height: 44px;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 0;
}
.slider-demo-block .demonstration + .el-slider {
  flex: 0 0 70%;
}
</style>

<style>
.table-column .cell {
  overflow: hidden;
}
</style>