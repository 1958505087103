<template>
  <el-descriptions
    size="small"
    direction="horizontal"
    :column="1"
    :border="true"
  >
    <el-descriptions-item label="ID Sistema">
      {{ arvoreData.id }}
    </el-descriptions-item>
    <el-descriptions-item label="ID Externo">
      {{ arvoreData.externalId }}
    </el-descriptions-item>
    <el-descriptions-item label="Contato com a Rede">
      {{ arvoreData.contatoRede }}
    </el-descriptions-item>
    <el-descriptions-item label="Projeção Rede">
      {{ arvoreData.projecaoRede }}
    </el-descriptions-item>

    <el-descriptions-item label="Projeto">
      {{ arvoreData.projeto }}
    </el-descriptions-item>
    <el-descriptions-item label="Observação">
      {{ arvoreData.observacao }}
    </el-descriptions-item>
    <el-descriptions-item label="Data de Criação">
      {{ arvoreData.updatedAt }}
    </el-descriptions-item>
    <el-descriptions-item label="Latitude">
      {{ arvoreData.y}}
    </el-descriptions-item>
    <el-descriptions-item label="Longitude">
      {{ arvoreData.x }}
    </el-descriptions-item>
  </el-descriptions>
</template>

<script>
import { getArvoreInfo } from "../../services/agiltec.js";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import { Circle, Fill, Stroke, Style } from "ol/style";
import {transform} from 'ol/proj';


export default {
  name: "PointInfoComponent",
  props: ["arvoreId"],
  data() {
    return {
      arvoreData: {},
      layer: null,
    };
  },
  mounted() {
    this.getData();
    this.layer = this.createLayer();
    this.app.map.addLayer(this.layer);
    this.emitter.on("point-info-closed", this.clearPoint);
    this.emitter.on("point-info-open", this.getData);
  },
  methods: {
    clearPoint() {
      setTimeout(() => {
        this.layer.getSource().clear();
      }, 2000);
    },
    getData() {
      if (this.arvoreId) {
        getArvoreInfo(this.arvoreId).then((r) => {
         // const date = new Date(r.data.updatedAt);
          this.arvoreData = {
            id: r.data.id,
            externalId: r.data.externalId,
            contatoRede: r.data.contatoRede.join(" - ") ,
            projecaoRede:  r.data.projecaoRede.join(" - ") ,
            tecnico: r.data.tecnico ? r.data.tecnico : '-',
            projeto: r.data.projeto ? r.data.projeto : '-',
            observacao: r.data.observacao ? r.data.observacao : '-',
            updatedAt: r.data.dataHora,
            x: r.data.geom ? r.data.geom.coordinates[0] : 0,
            y: r.data.geom ? r.data.geom.coordinates[1] : 0,
          }
          this.drawPoint();
        });
      }
    },
    createLayer() {
      const layer = new VectorLayer({
        source: new VectorSource({
          features: [],
        }),
        style: new Style({
          image: new Circle({
            radius: 14,
            stroke: new Stroke({ color: "cyan", width: 4 }),
            fill: new Fill({ color: "rgba(0,255,255,0.2)" }),
          }),
        }),
        zIndex: 99999,
      });
      return layer;
    },
    drawPoint() {

      let [x, y] = transform([this.arvoreData.x, this.arvoreData.y], 'EPSG:4326', 'EPSG:3857');

      const point = new Feature({
        geometry: new Point([x, y]),
      });

      this.layer.getSource().clear();
      this.layer.getSource().addFeature(point);
      this.app.map.getView().setCenter([x, y]);
      this.app.map.getView().setZoom(18);
    },
  },
  watch: {
    arvoreId() {
      this.getData();
    },
  },
};
</script>

<style>
</style>