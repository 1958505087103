<template>
  <el-dialog
    v-model="show"
    :title="'Árvore - ' + arvoreId"
    width="90%"
    top="5vh"
    @closed="closed"
  >
    <div class="container">
      <div class="row pc">
        <ArvoresImagesComponent :arvoreId="arvoreId" />
      </div>
      <div class="row">
        <ArvorePropertiesComponent :arvoreId="arvoreId" />
      </div>
    </div>
  </el-dialog>
</template>

<script>
import ArvoresImagesComponent from "./ArvoresImagesComponent.vue";
import ArvorePropertiesComponent from "./ArvorePropertiesComponent.vue";

export default {
  name: "ArvoreInfoComponent",
  components: {
    ArvoresImagesComponent,
    ArvorePropertiesComponent
  },
  data: () => {
    return {
      activeName: "first",
      show: false,
      arvoreId: null,
    };
  },
  methods: {
    open(arvoreId) {
      this.arvoreId = arvoreId;
      this.show = true;
      this.emitter.emit("point-info-open");
    },
    closed() {
      this.emitter.emit("point-info-closed");
    },
  },
};
</script>

<style scoped>
.container {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
  height: 100%;
}
.container img {
  height: 100%;
  margin: 0;
}
.row {
  width: 48%;
  height: 70vh;
  padding: 0 10px;
}
.pc {
  padding-top: 6px;
}
</style>
<style>
.el-image {
  display: block;
  height: 100%;
  margin-bottom: 10px;
}
</style>