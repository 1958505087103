<template>
  <div class="box">
    <div class="row container">
      <div class="control-bar">
        <ControlBarComponent />
      </div>
      <div class="content">
        <img class="logo" src="agiltec.png" alt="" srcset="" />
        <GeoCodingComponent />
        <MapComponent />
        <FooterComponent />
      </div>
    </div>
    <PointInfoComponent ref="postDataView" />
    <ArvoreInfoComponent ref="arvoreDataView" />
  </div>
  <LoginComponent />
</template>

<script>
import MapComponent from "./components/MapComponent.vue";
import FooterComponent from "./components/FooterComponent.vue";
import ControlBarComponent from "./components/ControlBarComponent.vue";
import GeoCodingComponent from "./components/map/GeoCodingComponent.vue";
import PointInfoComponent from "./components/data/PointInfoComponent.vue";
import LoginComponent from "./components/LoginComponent.vue";
import ArvoreInfoComponent from "./components/arvore/ArvoreInfoComponent.vue";

export default {
  name: "App",
  components: {
    MapComponent,
    FooterComponent,
    ControlBarComponent,
    GeoCodingComponent,
    PointInfoComponent,
    LoginComponent,
    ArvoreInfoComponent,
  },
};
</script>

<style>
* {
  font-family: "Roboto", sans-serif !important;
}
html,
body,
#app {
  height: 100%;
  margin: 0;
  overflow: hidden;
}
.box {
  display: flex;
  flex-flow: column;
  height: 100%;
}
.box .row.header {
  flex: 0 1 auto;
}
.box .row.container {
  flex: 1 1 auto;
  display: flex;
  flex-flow: row;
}
.box .row.footer {
  flex: 0 1 8px;
}
.content {
  flex: 1 1 auto;
}
.control-bar {
  flex: 0 0 3rem;
  background: #1d2731;
}
.logo {
  position: absolute;
  top: 16px;
  right: 12px;
  width: 80px;
  z-index: 1;
  opacity: 0.5;
}
</style>
