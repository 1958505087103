<template>
  <WidgetComponent title="Informações da Árvore" width="500px">
    <el-result :title="msg"> </el-result>
  </WidgetComponent>
</template>

<script>
import WidgetComponent from "../WidgetComponent.vue";
import { getArvoresByCooordinates } from "../../services/agiltec.js";

export default {
  name: "MapArvoreInfoComponent",
  components: {
    WidgetComponent,
  },
  props: ["componentId"],
  data() {
    return {
      arvoreId: null,
      showArvoreData: false,
      msg: "Clique sobre o mapa para obter informações de uma árvore.",
    };
  },
  mounted() {
    const mapContainer = document.getElementById("map");
    mapContainer.style.cursor = "crosshair";

    this.registerEvents();
  },
  methods: {
    mapClickEvent(evt) {
      this.msg = "Buscando informações da árvore...";
      getArvoresByCooordinates(evt.coordinate).then((response) => {
        if (response.features.length == 0) {
          this.msg = "Nenhuma árvore encontrada.";
          setTimeout(() => {
            this.msg =
              "Clique sobre o mapa para obter informações de uma árvore.";
          }, 3000);
          return;
        }
        this.arvoreId = response.features[0].properties.id;
        this.showArvoreData = true;

        this.$root.$refs.arvoreDataView.open(
          response.features[0].properties.id
        );
        this.msg = "Clique sobre o mapa para obter informações de uma árvore.";
      });
    },
    onCloseEvt(componentId) {
      if (componentId == this.componentId) {
        const mapContainer = document.getElementById("map");
        mapContainer.style.cursor = "unset";
        this.app.map.un("click", this.mapClickEvent);
        this.emitter.off("close-widget", this.onCloseEvt);
      }
    },
    registerEvents() {
      this.emitter.on("close-widget", this.onCloseEvt);
      this.app.map.on("click", this.mapClickEvent);
    },
  },
};
</script>

<style scoped>
</style>
