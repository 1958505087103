<template>
  <div id="scalebar"></div>
</template>

<script>
import { ScaleLine } from "ol/control";

export default {
  name: "ScaleBarComponent",
  mounted() {
    const scalebar = new ScaleLine({
      units: "metric",
      bar: false,
      minWidth: 50,
      maxWidth: 200,
      target: "scalebar",
    });
    this.app.map.addControl(scalebar);
  },
};
</script>
<style >
.ol-scale-line{
  padding: 8px 0 !important;
  margin: 0 12px 0 -5px !important;
  position: relative;
}

#scalebar{
  padding: 0;
  margin: 0;
  width: 100%;
}
</style>
