<template>
  <el-card
    class="box-card widget"
    :style="style"
    @click="handleZIndex"
    :ref="widgetReference"
    :id="widgetReference"
    @mousedown.capture="dragMouseDown"
  >
    <template #header>
      <div class="card-header disable-select">
        <el-row :gutter="10">
          <el-col :span="16" class="title">
            {{ title }}
          </el-col>
          <el-col :span="8">
            <el-space wrap class="align-right">
              <el-tooltip
                class="box-item"
                effect="dark"
                content="Encolher"
                placement="left"
                v-if="enableCollapse"
              >
                <el-button
                  class="open"
                  @click="handleRetract"
                  size="small"
                  circle
                >
                  ᐅ
                </el-button>
              </el-tooltip>
              <el-tooltip
                class="box-item"
                effect="dark"
                content="Fechar"
                placement="left"
              >
                <el-button @click="close" size="small" circle>x</el-button>
              </el-tooltip>
            </el-space>
          </el-col>
        </el-row>
      </div>
    </template>
    <a href="#" v-if="!showContent" @click="handleRetract">
      Mostrar conteúdo...
    </a>
    <slot v-if="showContent"></slot>
  </el-card>
</template>

<script>
import { v4 as uuidv4 } from "uuid";

export default {
  name: "WidgetComponent",
  data: function () {
    return {
      style: {
        height: this.height || "unset",
        width: this.width || "unset",
        top: "0px",
        left: this.left || "52px",
        right: this.right || "unset",
        zIndex: this.app.widgetZIndex++,
      },
      showContent: true,
      pos1: 0,
      pos2: 0,
      pos3: 0,
      pos4: 0,
      widgetReference: uuidv4(),
    };
  },
  props: ["title", "width", "height", "left", "right", "enableCollapse"],
  methods: {
    close() {
      this.$emit("close");
    },
    setHeight(v) {
      this.style.height = v;
    },
    setWidth(v) {
      this.style.width = v;
    },
    setTop(v) {
      this.style.top = v;
    },
    setleft() {
      this.style.left = "100px";
    },
    handleRetract() {
      this.showContent = !this.showContent;
    },
    handleZIndex() {
      this.style.zIndex = this.app.widgetZIndex++;
    },
    dragMouseDown(e) {
      this.pos1 = 0;
      this.pos2 = 0;
      this.pos3 = 0;
      this.pos4 = 0;
      //   e = e || window.event;
      this.pos3 = e.clientX;
      this.pos4 = e.clientY;
      document.onmouseup = this.closeDragElement;
      document.onmousemove = this.elementDrag;
    },
    closeDragElement() {
      document.onmouseup = null;
      document.onmousemove = null;
      //this.verifyDivIsInVisibleArea();
    },
    elementDrag(e) {
      //e = e || window.event;
      //e.preventDefault();
      this.pos1 = this.pos3 - e.clientX;
      this.pos2 = this.pos4 - e.clientY;
      this.pos3 = e.clientX;
      this.pos4 = e.clientY;
      this.style.top =
        this.$refs[this.widgetReference].$el.offsetTop - this.pos2 + "px";
      this.style.left =
        this.$refs[this.widgetReference].$el.offsetLeft - this.pos1 + "px";
    },
  },
};
</script>

<style>
.el-card__header {
  background: #eeeeee;
}
</style>

<style scoped>
.card-header {
  /* display: flex;
  justify-content: space-between;
  align-items: center; */
  height: 12px;
  width: 100% !important;
  padding: 0 0 8px 0;
}

.align-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-align: right;
}

.title {
  font-size: 1.1rem;
  font-weight: 600;
  color: rgb(85, 85, 85);
}

.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}

.box-card {
  width: 480px;
  /* backdrop-filter: blur(32px); */
}

.widget {
  position: absolute;
  background: rgba(255, 255, 255, 0.95);
  z-index: 100;
}

.open {
  transform: rotate(-90deg);
}

.disable-select {
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}
</style>