const agiltec_url = process.env.AGILTEC_URL || 'https://webgis.agiltec.com/api/';
const imageserver_url = process.env.IMAGESERVER_URL || 'https://webgis.agiltec.com/fotos/';
const geoserver_url = process.env.GEOSERVER_URL || 'https://webgis.agiltec.com/geoserver';
const webgis_url = process.env.WEBGIS_URL || 'https://webgis.agiltec.com/arvoreweb/';

export default {
    "routes": [
        {
            "title": "Árvores",
            "path": "arvores",
            "layers": [ "Árvores", "OpenStreetMap", "ESRI Satélite", "Divisão Administrativa" ],
        },
    ],    
    "timeseries": [
        {
            "name": "pontogeoficos",
            "service": "https://webgis.agiltec.com/geoserver/agiltecwebgis/wms?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetMap&FORMAT=image%2Fpng&TRANSPARENT=true&layers=agiltecwebgis%3Apostes_neo&CRS=EPSG%3A3857&STYLES=",
            "initialTime": "0000-01-01",
            "hiddenLayers": ["Mapa de Calor - Postes"]
        }
    ],
    "querybuilder": [
        {
            "title": "Tipo de Lâmpada x Potência",
            "name": "lampada_potencia",
            "filters": [
                {
                    "title": "Município",
                    "name": "municipio",
                    "service": "https://webgis.agiltec.com/geoserver/agiltecwebgis/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=agiltecwebgis%3Amunicipio&outputFormat=application%2Fjson&PropertyName=mun_me,mun_id",
                    "label": "mun_me",
                    "value": "mun_id",
                    "fixed": true
                },
                {
                    "title": "Tipo de lâmpada   ",
                    "name": "tipo_lampada",
                    "service": "https://webgis.agiltec.com/geoserver/agiltecwebgis/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=agiltecwebgis%3Atipo_de_lampada&maxFeatures=50&outputFormat=application%2Fjson",
                    "label": "tb_lp_descricao",
                    "value": "tb_lp_id"
                },
                {
                    "title": "Potência (W)",
                    "name": "tipo_potencia",
                    "service": "https://webgis.agiltec.com/geoserver/agiltecwebgis/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=agiltecwebgis%3Atipo_de_potencia&outputFormat=application%2Fjson",
                    "label": "tb_po_descricao",
                    "value": "tb_po_id"
                }
            ],
            "service": "https://webgis.agiltec.com/geoserver/agiltecwebgis/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=agiltecwebgis%3Avw_ip_tipo&outputFormat=application%2Fjson&cql_filter=mun_id={{municipio}} and tb_lp_id='{{tipo_lampada}}' and tb_pip_id={{tipo_potencia}}",
            "hiddenLayers": ["Mapa de Calor - Postes"]
        },
        {
            "title": "Tipo de Empresa Ocupante",
            "name": "empresa_ocupante",
            "filters": [
                {
                    "title": "Município",
                    "name": "municipio",
                    "service": "https://webgis.agiltec.com/geoserver/agiltecwebgis/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=agiltecwebgis%3Amunicipio&outputFormat=application%2Fjson&PropertyName=mun_me,mun_id",
                    "label": "mun_me",
                    "value": "mun_id",
                    "fixed": true
                },
                {
                    "title": "Ocupante",
                    "name": "ocupante",
                    "service": "https://webgis.agiltec.com/geoserver/agiltecwebgis/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=agiltecwebgis%3Atipo_de_uso_mutuo&maxFeatures=50&outputFormat=application%2Fjson",
                    "label": "tb_us_descricao",
                    "value": "tb_us_id",
                },
            ],
            "service": "https://webgis.agiltec.com/geoserver/agiltecwebgis/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=agiltecwebgis%3Avw_uso_mutuo_mun&outputFormat=application%2Fjson&cql_filter=mun_id={{municipio}} and tb_us_id={{ocupante}}",
            "hiddenLayers": ["Mapa de Calor - Postes"]
        },
        {
            "title": "Tipo de Poste/IP/UM",
            "name": "tipo_poste",
            "filters": [
                {
                    "title": "Município",
                    "name": "municipio",
                    "service": "https://webgis.agiltec.com/geoserver/agiltecwebgis/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=agiltecwebgis%3Amunicipio&outputFormat=application%2Fjson&PropertyName=mun_me,mun_id",
                    "label": "mun_me",
                    "value": "mun_id",
                    "fixed": true
                },
                {
                    "title": "Tipo de Característica",
                    "name": "ocupante",
                    "service": "https://webgis.agiltec.com/geoserver/agiltecwebgis/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=agiltecwebgis%3Atipo_de_uso_mutuo&maxFeatures=50&outputFormat=application%2Fjson",
                    "label": "tb_us_descricao",
                    "value": "tb_us_id",
                },
            ],
            "service": "https://webgis.agiltec.com/geoserver/agiltecwebgis/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=agiltecwebgis%3Avw_ip_tipo&outputFormat=application%2Fjson&cql_filter=mun_id={{municipio}} and tb_us_id={{ocupante}}",
            "hiddenLayers": ["Mapa de Calor - Postes"]
        }
    ],
    "services": {
        "google": {
            "apiKey": process.env.GOOGLE_API_KEY
        },
        "agiltec": {
            "url": "https://webgis.agiltec.com/api//",
            "apiKey": process.env.AGILTEC_API_KEY
        }
    },
    "center": [-48.3, -9.4],
    "zoom": 7,
    "maxZoom": 19,
    "groups": [
        {
            "title": "Divisão Administrativa",
            "layers": [
                {
                    "title": "Municípios",
                    "service": "https://webgis.agiltec.com/geoserver/agiltecwebgis/wms?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetMap&FORMAT=image%2Fpng&TRANSPARENT=true&layers=agiltecwebgis%3Amunicipios,agiltecwebgis%3Acidades&CRS=EPSG%3A3857&LEGEND_OPTIONS=dpi:120;fontAntiAliasing:true",
                    "type": "wms",
                    "visible": true,
                    "showLegend": false,
                    "legend": "https://webgis.agiltec.com/geoserver/agiltecwebgis/wms?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetLegendGraphic&FORMAT=image%2Fpng&TRANSPARENT=true&layer=agiltecwebgis%3Acidades&CRS=EPSG%3A3857&LEGEND_OPTIONS=dpi:120;fontAntiAliasing:true"
                },
                {
                    "title": "Estados",
                    "service": "https://webgis.agiltec.com/geoserver/agiltecwebgis/wms?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetMap&FORMAT=image%2Fpng&TRANSPARENT=true&layers=agiltecwebgis:estados&CRS=EPSG%3A3857&LEGEND_OPTIONS=dpi:120;fontAntiAliasing:true",
                    "type": "wms",
                    "visible": true,
                    "showLegend": false,
                    "legend": "https://webgis.agiltec.com/geoserver/agiltecwebgis/wms?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetLegendGraphic&FORMAT=image%2Fpng&TRANSPARENT=true&layer=agiltecwebgis%3Aestados&CRS=EPSG%3A3857&LEGEND_OPTIONS=dpi:120;fontAntiAliasing:true"
                }
            ]

        },
        {
            "title": "Árvores",
            "layers": [
                {
                    "title": "Contato Alta Tensão",
                    "service": "https://webgis.agiltec.com/geoserver/agiltecwebgis/wms?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetMap&FORMAT=image%2Fpng&TRANSPARENT=true&layers=agiltecwebgis%3Acontato_alta_tensao&CRS=EPSG%3A3857&LEGEND_OPTIONS=dpi:120;fontAntiAliasing:true",
                    "type": "wms",
                    "visible": false,
                    "showLegend": true,
                    "legend": "https://webgis.agiltec.com/geoserver/agiltecwebgis/wms?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetLegendGraphic&FORMAT=image%2Fpng&TRANSPARENT=true&layer=agiltecwebgis%3Acontato_alta_tensao&CRS=EPSG%3A3857&LEGEND_OPTIONS=fontAntiAliasing:true;fontColor:0x606266;fontName:Arial;fontSize:12;fontWeight:800"
                },
                {
                    "title": "Contato Média Tensão",
                    "service": "https://webgis.agiltec.com/geoserver/agiltecwebgis/wms?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetMap&FORMAT=image%2Fpng&TRANSPARENT=true&layers=agiltecwebgis%3Acontato_media_tensao&CRS=EPSG%3A3857&LEGEND_OPTIONS=dpi:120;fontAntiAliasing:true",
                    "type": "wms",
                    "visible": false,
                    "showLegend": true,
                    "legend": "https://webgis.agiltec.com/geoserver/agiltecwebgis/wms?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetLegendGraphic&FORMAT=image%2Fpng&TRANSPARENT=true&layer=agiltecwebgis%3Acontato_media_tensao&CRS=EPSG%3A3857&LEGEND_OPTIONS=fontAntiAliasing:true;fontColor:0x606266;fontName:Arial;fontSize:12;fontWeight:800"
                },
                {
                    "title": "Contato Baixa Tensão",
                    "service": "https://webgis.agiltec.com/geoserver/agiltecwebgis/wms?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetMap&FORMAT=image%2Fpng&TRANSPARENT=true&layers=agiltecwebgis%3Acontato_baixa_tensao&CRS=EPSG%3A3857&LEGEND_OPTIONS=dpi:120;fontAntiAliasing:true",
                    "type": "wms",
                    "visible": false,
                    "showLegend": true,
                    "legend": "https://webgis.agiltec.com/geoserver/agiltecwebgis/wms?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetLegendGraphic&FORMAT=image%2Fpng&TRANSPARENT=true&layer=agiltecwebgis%3Acontato_baixa_tensao&CRS=EPSG%3A3857&LEGEND_OPTIONS=fontAntiAliasing:true;fontColor:0x606266;fontName:Arial;fontSize:12;fontWeight:800"
                },
                {
                    "title": "Contato Ramal",
                    "service": "https://webgis.agiltec.com/geoserver/agiltecwebgis/wms?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetMap&FORMAT=image%2Fpng&TRANSPARENT=true&layers=agiltecwebgis%3Acontato_ramal&CRS=EPSG%3A3857&LEGEND_OPTIONS=dpi:120;fontAntiAliasing:true",
                    "type": "wms",
                    "visible": false,
                    "showLegend": true,
                    "legend": "https://webgis.agiltec.com/geoserver/agiltecwebgis/wms?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetLegendGraphic&FORMAT=image%2Fpng&TRANSPARENT=true&layer=agiltecwebgis%3Acontato_ramal&CRS=EPSG%3A3857&LEGEND_OPTIONS=fontAntiAliasing:true;fontColor:0x606266;fontName:Arial;fontSize:12;fontWeight:800"
                },
                {
                    "title": "Projeção Alta Tensão",
                    "service": "https://webgis.agiltec.com/geoserver/agiltecwebgis/wms?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetMap&FORMAT=image%2Fpng&TRANSPARENT=true&layers=agiltecwebgis%3Aprojecao_alta_tensao&CRS=EPSG%3A3857&LEGEND_OPTIONS=dpi:120;fontAntiAliasing:true",
                    "type": "wms",
                    "visible": false,
                    "showLegend": true,
                    "legend": "https://webgis.agiltec.com/geoserver/agiltecwebgis/wms?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetLegendGraphic&FORMAT=image%2Fpng&TRANSPARENT=true&layer=agiltecwebgis%3Aprojecao_alta_tensao&CRS=EPSG%3A3857&LEGEND_OPTIONS=fontAntiAliasing:true;fontColor:0x606266;fontName:Arial;fontSize:12;fontWeight:800"
                },
                {
                    "title": "Projeção Média Tensão",
                    "service": "https://webgis.agiltec.com/geoserver/agiltecwebgis/wms?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetMap&FORMAT=image%2Fpng&TRANSPARENT=true&layers=agiltecwebgis%3Aprojecao_media_tensao&CRS=EPSG%3A3857&LEGEND_OPTIONS=dpi:120;fontAntiAliasing:true",
                    "type": "wms",
                    "visible": false,
                    "showLegend": true,
                    "legend": "https://webgis.agiltec.com/geoserver/agiltecwebgis/wms?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetLegendGraphic&FORMAT=image%2Fpng&TRANSPARENT=true&layer=agiltecwebgis%3Aprojecao_media_tensao&CRS=EPSG%3A3857&LEGEND_OPTIONS=fontAntiAliasing:true;fontColor:0x606266;fontName:Arial;fontSize:12;fontWeight:800"
                },
                {
                    "title": "Projeção Baixa Tensão",
                    "service": "https://webgis.agiltec.com/geoserver/agiltecwebgis/wms?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetMap&FORMAT=image%2Fpng&TRANSPARENT=true&layers=agiltecwebgis%3Aprojecao_baixa_tensao&CRS=EPSG%3A3857&LEGEND_OPTIONS=dpi:120;fontAntiAliasing:true",
                    "type": "wms",
                    "visible": false,
                    "showLegend": true,
                    "legend": "https://webgis.agiltec.com/geoserver/agiltecwebgis/wms?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetLegendGraphic&FORMAT=image%2Fpng&TRANSPARENT=true&layer=agiltecwebgis%3Aprojecao_baixa_tensao&CRS=EPSG%3A3857&LEGEND_OPTIONS=fontAntiAliasing:true;fontColor:0x606266;fontName:Arial;fontSize:12;fontWeight:800"
                },
                {
                    "title": "Projeção Ramal",
                    "service": "https://webgis.agiltec.com/geoserver/agiltecwebgis/wms?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetMap&FORMAT=image%2Fpng&TRANSPARENT=true&layers=agiltecwebgis%3Aprojecao_ramal&CRS=EPSG%3A3857&LEGEND_OPTIONS=dpi:120;fontAntiAliasing:true",
                    "type": "wms",
                    "visible": false,
                    "showLegend": true,
                    "legend": "https://webgis.agiltec.com/geoserver/agiltecwebgis/wms?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetLegendGraphic&FORMAT=image%2Fpng&TRANSPARENT=true&layer=agiltecwebgis%3Aprojecao_ramal&CRS=EPSG%3A3857&LEGEND_OPTIONS=fontAntiAliasing:true;fontColor:0x606266;fontName:Arial;fontSize:12;fontWeight:800"
                },
                {
                    "title": "Árvores",
                    "service": "https://webgis.agiltec.com/geoserver/agiltecwebgis/wms?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetMap&FORMAT=image%2Fpng&TRANSPARENT=true&layers=agiltecwebgis%3Aarvores&CRS=EPSG%3A3857&LEGEND_OPTIONS=dpi:120;fontAntiAliasing:true",
                    "type": "wms",
                    "visible": false,
                    "showLegend": true,
                    "legend": "https://webgis.agiltec.com/geoserver/agiltecwebgis/wms?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetLegendGraphic&FORMAT=image%2Fpng&TRANSPARENT=true&layer=agiltecwebgis%3Aarvores&CRS=EPSG%3A3857&LEGEND_OPTIONS=fontAntiAliasing:true;fontColor:0x606266;fontName:Arial;fontSize:12;fontWeight:800"
                },
            ]

        },
        {
            "title": "Rotas Consumidores",
            "layers": [
                {
                    "title": "Grupos de Consumidores",
                    "service": "https://webgis.agiltec.com/geoserver/agiltecwebgis/wms?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetMap&FORMAT=image%2Fpng&TRANSPARENT=true&layers=agiltecwebgis%3Aconsumers&CRS=EPSG%3A3857&LEGEND_OPTIONS=dpi:120;fontAntiAliasing:true",
                    "type": "wms",
                    "visible": false,
                },
                {
                    "title": "Grupos de Consumidores",
                    "service": "https://webgis.agiltec.com/geoserver/agiltecwebgis/wms?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetMap&FORMAT=image%2Fpng&TRANSPARENT=true&layers=agiltecwebgis%3Agroups&CRS=EPSG%3A3857&LEGEND_OPTIONS=dpi:120;fontAntiAliasing:true",
                    "type": "wms",
                    "visible": false,
                },
            ]

        },
        {
            "title": "Energisa",
            "layers": [
                {
                    "title": "Mapa de Calor - Postes",
                    "service": "https://webgis.agiltec.com/geoserver/agiltecwebgis/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=agiltecwebgis%3Adistribuicao_postes_500_point&outputFormat=application%2Fjson",
                    "type": "heatmap",
                    "visible": true,
                    "radius": 3,
                    "blur": 30,
                    "minResolution": 20,
                },
                {
                    "title": "Uso Mútuo",
                    "service": "https://webgis.agiltec.com/geoserver/agiltecwebgis/wms?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetMap&FORMAT=image%2Fpng&TRANSPARENT=true&layers=agiltecwebgis%3Auso_mutuo_neo&CRS=EPSG%3A3857&STYLES=",
                    "type": "wms",
                    "visible": true,
                    "showLegend": true,
                    "legend": "https://webgis.agiltec.com/geoserver/agiltecwebgis/wms?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetLegendGraphic&FORMAT=image%2Fpng&TRANSPARENT=true&layer=agiltecwebgis%3Auso_mutuo_neo&CRS=EPSG%3A3857&LEGEND_OPTIONS=fontAntiAliasing:true;fontColor:0x606266;fontName:Arial;fontSize:12;fontWeight:800"
                },
                {
                    "title": "Iluminação Pública",
                    "service": "https://webgis.agiltec.com/geoserver/agiltecwebgis/wms?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetMap&FORMAT=image%2Fpng&TRANSPARENT=true&layers=agiltecwebgis%3Aip_neo&CRS=EPSG%3A3857&STYLES=",
                    "type": "wms",
                    "visible": true,
                    "showLegend": true,
                    "legend": "https://webgis.agiltec.com/geoserver/agiltecwebgis/wms?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetLegendGraphic&FORMAT=image%2Fpng&TRANSPARENT=true&layer=agiltecwebgis%3Aip_neo&CRS=EPSG%3A3857&LEGEND_OPTIONS=fontAntiAliasing:true;fontColor:0x606266;fontName:Arial;fontSize:12;fontWeight:800"
                },
                {
                    "title": "Postes",
                    "service": "https://webgis.agiltec.com/geoserver/agiltecwebgis/wms?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetMap&FORMAT=image%2Fpng&TRANSPARENT=true&layers=agiltecwebgis%3Apostes_neo&CRS=EPSG%3A3857&STYLES=",
                    "type": "wms",
                    "visible": true,
                    "showLegend": true,
                    "legend": "https://webgis.agiltec.com/geoserver/agiltecwebgis/wms?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetLegendGraphic&FORMAT=image%2Fpng&TRANSPARENT=true&layer=agiltecwebgis%3Apostes_neo&CRS=EPSG%3A3857&LEGEND_OPTIONS=fontAntiAliasing:true;fontColor:0x606266;fontName:Arial;fontSize:12;fontWeight:800",
                    "minResolution": 0,
                    "maxResolution": 70,
                    "history": {
                        "service": "https://webgis.agiltec.com/geoserver/agiltecwebgis/wms?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetMap&FORMAT=image%2Fpng&TRANSPARENT=true&layers=agiltecwebgis%3Apostes_historico&CRS=EPSG%3A3857&STYLES=",
                        "type": "wms",
                        "timeField": "pto_data_coleta",
                    }
                },
            ]
        },
        {
            "title": "Mapas Base",
            "layers": [
                {
                    "title": "OpenStreetMap",
                    "service": "https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png",
                    "type": "xyz",
                    "visible": true,
                    "minResolution": 0,
                    "maxResolution": 70,
                },
                {
                    "title": "ESRI Satélite",
                    "service": "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
                    "type": "xyz",
                    "visible": true,
                    "minResolution": 70,
                    "maxResolution": 200000,
                },
                {
                    "title": "Google Satélite",
                    "service": "http://mt1.google.com/vt/lyrs=y&hl=pl&&x={x}&y={y}&z={z}",
                    "type": "xyz",
                    "visible": false,

                },
                {
                    "title": "Positron - Carto Light",
                    "service": "http://cartodb-basemaps-a.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png",
                    "type": "xyz",
                    "visible": false,

                }
            ]

        }
    ],
    "agiltec_url": agiltec_url,
    "imageserver_url": imageserver_url,
    "geoserver_url": geoserver_url,
    "webgis_url": webgis_url,
}