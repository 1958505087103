import { createApp } from 'vue';
import ElementPlus from 'element-plus';
import ptBr from 'element-plus/es/locale/lang/pt-br'
import mitt from 'mitt';
import 'element-plus/dist/index.css';
import App from './App.vue';
import config from '../app.config.js'
import { handleRouteLayers } from './helpers/utils.js';

const emitter = mitt();
const app = createApp(App);
app.use(ElementPlus, {
    locale: ptBr,
});

app.config.globalProperties.emitter = emitter;
app.config.globalProperties.app = {};
app.config.globalProperties.app.config = config;
app.config.globalProperties.app.widgetZIndex = 1000;
app.mount('#app');

window.emitter = emitter;
window.app = app.config.globalProperties.app;

let hash = window.location.hash.replace('#/', '');
handleRouteLayers(config, hash);
window.addEventListener('hashchange', function () {
    let hash = window.location.hash.replace('#/', '');
    handleRouteLayers(config, hash);
});
