<template>
  <div>
    <div class="data">
      Total de Pontos: <span>{{ totalPontos }}</span> Total Uso Compartilhado:
      <span>{{ totalUsoCompartilhado }}</span>
      Total IP:
      <span>{{ totalIp }}</span> Total de Pontos sem IP/Uso Compartilhado:
      <span>{{ totalSemIp }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "TotalComponent",
  props: ["totalPontos", "totalUsoCompartilhado", "totalIp", "totalSemIp"],
};
</script>

<style scoped>
.data {
  width: 100%;
  text-align: center;
  font-size: 0.9rem;
  font-weight: bold;
  color: rgb(78, 78, 78);
  padding: 1rem 0;
}
.data span {
  background: #dadada;
  padding: 0.3rem 1rem;
  border-radius: 0.6rem;
  margin-right: 2rem;
  margin-left: 0.1rem;
}
</style>