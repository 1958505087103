<template>
  <div class="footer">
    <div class="control">
      <MouseCoordinate />
    </div>
    <div class="control">
      <ScaleBarComponent />
    </div>
    <div class="control" v-if="isLoading">
      <LoadingLayerCompoment />
    </div>
  </div>
</template>

<script>
import ScaleBarComponent from "./map/ScaleBarComponent.vue";
import MouseCoordinate from "./map/MouseCoordinate.vue";
import LoadingLayerCompoment from "./map/LoadingLayerCompoment.vue";

export default {
  name: "FooterComponent",
  components: { ScaleBarComponent, MouseCoordinate, LoadingLayerCompoment },
  data() {
    return {
      isLoading: false,
      loadingCount: 0,
    };
  },
  mounted() {
    this.emitter.on("loading-started", () => {
      this.loadingCount++;
    });
    this.emitter.on("loading-finished", () => {
      this.loadingCount--;
    });
  },
  watch: {
    loadingCount(newValue) {
      this.isLoading = newValue > 0;
    },
  },
};
</script>

<style >
.footer {
  height: 24px;
  font-size: 14px;
  position: absolute;
  bottom: 4px;
  left: 42;
  background: none !important;
  display: flex;
  z-index: 10;
}
.control {
  float: left;
  background: #1d2731;
  width: fit-content;
  border-radius: 16px;
  margin: 0 4px;
  padding: 4px 8px;
}
</style>
