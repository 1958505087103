<template>
  <div v-if="show" class="bg-cover">
    <div class="bg-blur">
      <div class="full">
        <el-card class="login">
          <img src="../assets/topo-marca.png" alt="" srcset="" />
          <h3>Entre com seu login e senha</h3>
          <p v-if="error" class="error">Usuário/Senha não conferem.</p>
          <el-form label-width="120px" labelPosition="top">
            <el-form-item label="Usuário">
              <el-input v-model="user" />
            </el-form-item>
            <el-form-item label="Senha">
              <el-input v-model="pass" type="password" />
            </el-form-item>
            <el-button class="login-btn" @click="login">Entrar</el-button>
          </el-form>
          <p>Agiltec © 2022 WebGIS IP</p>
        </el-card>
      </div>
    </div>
  </div>
</template>

<script>
//import { login } from "@/services/agiltec";
export default {
  name: "LoginComponent",
  data() {
    return {
      user: "",
      pass: "",
      error: false,
      show: false,
    };
  },
  mounted() {
    const logged = localStorage.getItem("logged");
    const isTimeOk =
      parseInt(localStorage.getItem("loggedTime")) + 1.44e7 > Date.now();
    if (logged && isTimeOk) {
      this.show = false;
    } else {
      this.show = true;
    }
  },
  methods: {
    login() {
      // login(this.user, this.pass).then((response) => {
      //   if (response.Success) {
      //     this.show = true;
      //   } else {
      //     this.error = true;
      //   }
      // });

      if (
        this.user == "27172" ||
        this.user == "Energisa" ||
        (this.user == "prefeitura" && this.pass == "prefeitura")
      ) {
        this.show = false;
        localStorage.setItem("logged", "true");
        localStorage.setItem("loggedTime", Date.now());
      } else {
        this.error = true;
      }
    },
  },
};
</script>

<style scoped>
.bg-cover {
  height: 100%;
  width: 100%;
  background: url("../assets/entrada.jpg") center center no-repeat;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 999;
}
.bg-blur {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 9999;
  backdrop-filter: blur(2px);
  -moz-backdrop-filter: blur(2px);
}
.full {
  height: 100%;
  width: 100%;
  background: url("../assets/entrada.jpg") center center no-repeat;
  background-size: contain;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 99999;
}
.login {
  width: 400px;
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 9999;
  transform: translate(-50%, -50%);
}
.login h3 {
  text-align: center;
  margin-bottom: 20px;
  font-weight: 500;
}
.login img {
  display: block;
  margin: auto;
  padding: 8px;
}
.login p {
  width: 100% !important;
  text-align: center;
  font-size: 12px;
  color: rgb(141, 141, 141);
  padding-top: 16px;
}
.login-btn {
  width: 100%;
  margin-top: 16px;
}
.error {
  color: red !important;
}
</style>