<template>
  <div id="map"></div>
  <component v-bind:is="overViewComponent"></component>
</template>

<script>
import "ol/ol.css";
import Map from "ol/Map";
import View from "ol/View";
import { transform } from "ol/proj";
import { createLayers } from "../helpers/layers.js";
import OverViewComponent from "./map/OverViewComponent.vue";

export default {
  name: "MapComponent",
  // eslint-disable-next-line
  components: { OverViewComponent },
  data() {
    return {
      overViewComponent: null,
      loadingLayerCompoment: null,
    };
  },
  mounted() {
    const center = transform(this.app.config.center, "EPSG:4326", "EPSG:3857");
    this.app.map = new Map({
      target: "map",
      view: new View({
        center: center || [0, 0],
        zoom: this.app.config.zoom || 0,
        maxZoom: this.app.config.maxZoom || 18,
        minZoom: this.app.config.minZoom || 0,
      }),
      controls: [],
      layers: createLayers(this.app.config.groups),
    });
    this.overViewComponent = "OverViewComponent";
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >
#map {
  height: 100%;
  width: 100%;
  background: #f0f0f0;
}
.ol-scale-line {
  background: none;
}
</style>
