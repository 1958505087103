<template>
<div></div>
</template>
<script>
export default {
    name: "ZoomInComponent",
    methods: {
        execute() {
            this.app.map.getView().setZoom(this.app.map.getView().getZoom() + 1);
        },
    }
}
</script>